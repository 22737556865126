import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService, CustomAuthService } from "auth";
import { tap } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { FeatureService } from "../services/features.service";

@Component({
  selector: "app-react-native-bundle",
  templateUrl: "./react-native-bundle.component.html",
  styleUrls: ["./react-native-bundle.component.scss"],
})
export class ReactNativeBundleComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private authService: CustomAuthService,
    private route: ActivatedRoute,
    private featureService: FeatureService
  ) {}

  featureId;
  baseUrl;
  homeName;

  resetSafeAreaInsets() {
    const htmlElement = document.documentElement;
    const innerHeight = window.innerHeight;
    htmlElement.style.setProperty("--client-height", `${innerHeight}px`);
  }

  ngOnDestroy(): void {
    document.body.classList.remove("react-native-header-background");
  }

  ngOnInit(): void {
    this.resetSafeAreaInsets();
    document.body.classList.add("react-native-header-background");

    this.route.paramMap.subscribe((params) => {
      this.featureId = params.get("featureId");
      console.log(`feature id: ${this.featureId}`);
      this.featureService
        .getFeatureDescriptionByBasePath(this.featureId)
        .then((rslt) => {
          sessionStorage.setItem("feature-base-path", rslt.baseUrl);
          this.baseUrl = rslt.baseUrl;
          this.homeName = rslt.homeName;

          const navigationParam =
            this.route.snapshot.queryParamMap.get("navigationParam");

          console.log("resetting initial navigation : " + navigationParam);

          (window as any).initialNavigation = navigationParam;
          this.checkLastVersionAndThenLoad(this.featureId, navigationParam);
        });
    });
  }

  checkLastVersionAndThenLoad(featureId, navigationParam) {
    const version = new Date().getTime();
    console.log(`load: ${this.baseUrl}`);

    // Check index.html
    fetch(`${this.baseUrl}index.html?v=${version}`, {
      method: "HEAD",
    }).then((response) => {
      const lastModified = response.headers.get("Last-Modified");
      console.log("index.html last modified: " + lastModified);
      const cachedLastModified = localStorage.getItem(
        "index-html-last-modified"
      );

      if (lastModified !== cachedLastModified) {
        console.log("need new version of index.html");
        localStorage.setItem("index-html-last-modified", lastModified);
        this.checkBundleLastVersion(featureId, navigationParam, true);
      } else {
        console.log("index.html is up to date");
        this.checkBundleLastVersion(featureId, navigationParam, false);
      }
    });
  }

  checkBundleLastVersion(featureId, navigationParam, indexUpdated) {
    const version = new Date().getTime();

    // Check bundle file
    fetch(`${this.baseUrl}bundle.js?v=${version}`, {
      method: "HEAD",
    }).then((response) => {
      const lastModified = response.headers.get("Last-Modified");
      console.log("bundle.js last modified: " + lastModified);
      const cachedLastModified = localStorage.getItem("bundle-last-modified");

      if (lastModified !== cachedLastModified) {
        console.log("need new version of bundle.js");
        localStorage.setItem("bundle-last-modified", lastModified);
        this.loadReactNativeBundle(true, featureId, navigationParam);
      } else {
        if (indexUpdated) {
          console.log("index.html was updated, reloading bundle");
          this.loadReactNativeBundle(true, featureId, navigationParam);
        } else {
          console.log("loading cached bundle.js");
          this.loadReactNativeBundle(false, featureId, navigationParam); // this should load the cached version
        }
      }
    });
  }

  loadReactNativeBundle(appendVersion, featureId, navigation): void {
    console.log("navigation: " + navigation);
    const version = new Date().getTime();
    fetch(
      appendVersion
        ? this.baseUrl + `index.html?v=${version}&rand=${Date.now()}`
        : this.baseUrl + `index.html`
    )
      .then((response) => response.text())
      .then((html) => {
        console.log("body: " + document.getElementById("react-native-root"));

        // Inject the HTML into the component
        document.getElementById("react-native-body").innerHTML = html;

        // Inject script tags to load the React bundle
        const script = document.createElement("script");
        if (appendVersion) script.setAttribute("cache-control", "no-store");
        script.onload = function () {
          const computedStyle = getComputedStyle(document.documentElement);
          const htmlElement = document.documentElement;
          const clientHeight =
            computedStyle.getPropertyValue("--client-height");
          console.log(`ClientHeight: ${clientHeight}`);
          htmlElement.style.height = clientHeight.trim();
        };

        script.src = appendVersion
          ? this.baseUrl + `bundle.js?v=${version}&rand=${Date.now()}`
          : this.baseUrl + `bundle.js`;
        document.body.appendChild(script);
      })
      .catch((err) => console.warn("Error loading React app:", err));
  }
}
