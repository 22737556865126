<div id="sidebar" [ngClass]="{ hidden: sideNavigatorService.hideSideNav }">
  <div id="close-button">
    <img
      src="../../../assets/redesign/close-icon.svg"
      alt="close icon"
      (click)="sideNavigatorService.toggleSideNavigator()"
    />
  </div>
  <div id="toptab">
    <div class="spacer"></div>
    <div class="circle">
      <img
        src="../../../assets/redesign/person-menu-icon.svg"
        alt="icon"
        class="circle-icon"
      />
    </div>
  </div>
  <div id="sidebar-body">
    <div id="sidebar-title">
      <span>{{ firstName }} {{ lastName }}</span>
      <span>{{ orgName }}</span>
    </div>
    <div id="sidebar-content">
      <a
        [routerLink]="['/home']"
        class="link-content"
        (click)="sideNavigatorService.toggleSideNavigator()"
      >
        <img
          src="../../../assets/redesign/home-icon.svg"
          alt="home icon"
          (click)="
            sideNavigatorService.toggleSideNavigator(); $event.stopPropagation()
          "
        />
        <span
          (click)="
            sideNavigatorService.toggleSideNavigator(); $event.stopPropagation()
          "
          >Home</span
        >
      </a>
      <div
        class="link-content"
        (click)="sideNavigatorService.toggleSideNavigator(); auth.logout()"
      >
        <img src="../../../assets/redesign/logout-icon.svg" alt="logout icon" />
        <span>Logout</span>
      </div>
      <div class="content-buffer"></div>
    </div>
  </div>
  <div id="sidebar-footer"></div>
</div>
<!-- <nav
  id="sidebar"
  class="navbar-dark bg-dark"
  [ngClass]="{ hidden: sideNavigatorService.hideSideNav }"
> -->
<!-- <div
    class="paddingLT"
    *ngIf="
      functionalityService.includeAuthentication().toLowerCase() === 'true' &&
      (auth.isLoggedIn() | async)
    "
  >
    <span>Hello {{ userFirstName }}</span>
  </div>
  <div
    class="paddingLT"
    *ngIf="
      functionalityService.includeAuthentication().toLowerCase() === 'true' &&
      !(auth.isLoggedIn() | async)
    "
  >
    <span>You are not logged in</span>
  </div> -->
<!-- <ul class="navbar-nav">
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/home']">Home</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/about']">About Us</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/support']">Contact Us</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/disclaimer']">Disclaimer</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/release-notes']">Release Notes</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/version']">Version</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/push-support']">Push Support</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/react-test', 'dispatch']"
        >Dispatch</a
      >
    </li> -->
<!-- <li
      class="nav-item active"
      *ngIf="
        functionalityService.includeAuthentication().toLowerCase() === 'true' &&
        (auth.isLoggedIn() | async)
      "
    >
      <app-logout></app-logout>
    </li>
    <li
      class="nav-item active"
      *ngIf="
        functionalityService.includeAuthentication().toLowerCase() === 'true' &&
        !(auth.isLoggedIn() | async)
      "
    >
      <app-login></app-login>
    </li> -->
<!-- </ul>
</nav> -->
