import { Component, OnInit } from "@angular/core";
import { FunctionalityService } from "mn-common";
import { SideNavigatorService } from "../../services/side-navigator.service";
import { CustomAuthService } from "auth";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  firstName;
  lastName;
  orgName;

  constructor(
    public auth: CustomAuthService,
    public sideNavigatorService: SideNavigatorService,
    public functionalityService: FunctionalityService
  ) {}

  ngOnInit(): void {
    this.auth.getUserInfo().subscribe({
      next: (userInfo) => {
        console.log("UserInfo: " + userInfo);
        this.firstName = userInfo.firstname;
        this.lastName = userInfo.lastname;
      },
      error: (error) => {
        console.error("Error fetching user info:", error);
      },
      complete: () => {
        console.log("User info fetch completed");
      },
    });
    this.auth.getOrganization().subscribe({
      next: (org) => {
        this.orgName = org.name;
      },
      error: (error) => {
        console.error("Error fetching user organization:", error);
      },
      complete: () => {
        console.log("User organization fetch completed");
      },
    });
  }

  ngOnDestroy(): void {}
}
