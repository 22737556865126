import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, filter, forkJoin, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeatureService {
  constructor(private http: HttpClient) {}

  private features = [
    {
      id: "1",
      baseUrl:
        "https://sandbox-medicnow-app.s3.amazonaws.com/features/dispatch/",
    },
    {
      id: "2",
      baseUrl:
        "https://sandbox-medicnow-app.s3.amazonaws.com/features/contacts/",
    },
    {
      id: "3",
      baseUrl:
        "https://sandbox-medicnow-app.s3.amazonaws.com/features/useradmin/",
    },
    {
      id: "4",
      baseUrl:
        "https://sandbox-medicnow-app.s3.amazonaws.com/features/drugcards/",
    },
    {
      id: "5",
      baseUrl:
        "https://sandbox-medicnow-app.s3.amazonaws.com/features/legacyprotocols/",
    },
    {
      id: "6",
      baseUrl:
        "https://sandbox-medicnow-app.s3.amazonaws.com/features/infusions/",
    },
    {
      id: "7",
      baseUrl:
        "https://sandbox-medicnow-app.s3.amazonaws.com/features/prearrivalsender/",
    },
  ];

  private featureDescriptions: any[] = [];

  public getFeatures() {
    return this.features;
  }

  public getFeatureDescriptions(): Promise<any[]> {
    const requests = this.features.map((feature) =>
      fetch(feature.baseUrl + "feature-description.json", { cache: "no-cache" })
        .then((response) => {
          const result = response.json();
          console.log("feature-description");
          console.log(result);
          return result;
        })
        .then((data) => {
          console.log("test");
          console.log(data);
          const descriptor = data;
          return descriptor;
        })
        .catch((error) => {
          console.error("Error fetching feature descriptor:", error);
          return [];
        })
    );
    return Promise.all(requests).then((results) => {
      const filtered = results.filter((result) => result !== null);
      this.featureDescriptions = filtered;
      return filtered;
    });
  }

  public getBaseUrlByBasePath(basePath: string): Promise<any> {
    if (this.featureDescriptions.length == 0) {
      return this.getFeatureDescriptions().then((data) => {
        return this.featureDescriptions.find(
          (feature) => feature.defaultPath.replace(/\//g, "") === basePath
        ).baseUrl;
      });
    } else {
      return Promise.resolve(
        this.featureDescriptions.find(
          (feature) => feature.defaultPath.replace(/\//g, "") === basePath
        ).baseUrl
      );
    }
  }

  public getFeatureDescriptionByBasePath(basePath: string): Promise<any> {
    if (this.featureDescriptions.length == 0) {
      console.log("$$$");
      return this.getFeatureDescriptions().then((data) => {
        return this.featureDescriptions.find(
          (feature) => feature.defaultPath.replace(/\//g, "") === basePath
        );
      });
    } else {
      console.log("***");
      return Promise.resolve(
        this.featureDescriptions.find(
          (feature) => feature.defaultPath.replace(/\//g, "") === basePath
        )
      );
    }
  }

  public getFeatureDescriptionByBaseUrl(baseUrl: string): Promise<any> {
    if (this.featureDescriptions.length == 0) {
      return this.getFeatureDescriptions().then((data) => {
        return this.featureDescriptions.find(
          (feature) => feature.baseUrl === baseUrl
        );
      });
    } else {
      return Promise.resolve(
        this.featureDescriptions.find((feature) => feature.baseUrl === baseUrl)
      );
    }
  }
}
