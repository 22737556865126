import { CommonModule, ViewportScroller } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, OnInit } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  Router,
} from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import {
  AuthGuard,
  CustomAuthService,
  AuthModule as MyAuthModule,
  ReactNativeHttpService,
  ServiceBridge,
} from "auth";
import { BeaconsModule } from "beacons";
import _ from "lodash";
import { LoggingModule, LoggingService } from "logging";
import { FunctionalityService, MnCommonModule } from "mn-common";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { PushSupportModule } from "push-support";
import { ResourceContributionModule } from "resource-contribution";
import { UiNotificationModule } from "ui-notification";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AboutComponent } from "./components/about/about.component";
import { AdministrativeComponent } from "./components/administrative/administrative.component";
import { LoggedOutComponent } from "./components/auth/logged-out/logged-out.component";
import { LoginComponent } from "./components/auth/login/login.component";
import { LogoutComponent } from "./components/auth/logout/logout.component";
import { NotFoundComponent } from "./components/auth/not-found/not-found.component";
import { UnauthorizedComponent } from "./components/auth/unauthorized/unauthorized.component";
import { ContactsComponent } from "./components/contacts/contacts.component";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./components/home/home.component";
import { SafeHtmlPipe } from "./components/home/safe-html.pipe";
import { PromptComponent } from "./components/prompt/prompt.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SupportComponent } from "./components/support/support.component";
import { VersionComponent } from "./components/version/version.component";
import { HiddenPipe } from "./hidden.pipe";
import { SafePipe } from "./safe.pipe";
import { EnvironmentFunctionalityService } from "./services/functionality.service";
import { PwaService } from "./services/pwa.service";
import { MatRadioModule } from "@angular/material/radio";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ReactNativeBundleComponent } from "./react-native-bundle/react-native-bundle.component";

const initializer = (pwaService: PwaService) => () =>
  pwaService.initPwaPrompt();

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    HiddenPipe,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactsComponent,
    SidebarComponent,
    AdministrativeComponent,
    UnauthorizedComponent,
    LoginComponent,
    LogoutComponent,
    AboutComponent,
    DisclaimerComponent,
    VersionComponent,
    PromptComponent,
    SupportComponent,
    LoggedOutComponent,
    NotFoundComponent,
    SafeHtmlPipe,
    ReactNativeBundleComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatSlideToggleModule,
    MnCommonModule,
    MatSlideToggleModule,
    MatRadioModule,
    ScrollingModule,
    NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
    ServiceWorkerModule.register("combined-sw.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately",
      //registrationStrategy: "registerWhenStable:10000",
    }),
    UiNotificationModule,
    ResourceContributionModule,
    MyAuthModule.forRoot(
      "https://qaip1b5qa0.execute-api.us-east-2.amazonaws.com/default",
      "https://xamjhegffh.execute-api.us-east-2.amazonaws.com/default",
      "4ppHdu97lj378qeJnWBCD1P5xBlWP25v9fAfUiP9",
      "Zeo5B85AiY9f8IX2ZXZ7o6lpqwyo7Lfd7a1fzf27",
      environment.connection
    ),
    BeaconsModule.forRoot(
      "https://2hcvn1tre8.execute-api.us-east-2.amazonaws.com/default", // beacons
      "https://eqi7fmb3c5.execute-api.us-east-2.amazonaws.com/default", // asset mgmt
      "12idOq8UnQ5eqWrOPCdDBaG9btAAkbq93BW4tM3s", // beacon x-api-key
      "wD2qUnJd8CfadpJcZrUs8Onb8G8Ewei9bYrmnXQj", // gateway x-api-key
      "yH6zIkKPDR4niOYUgnc8p3efRO93C4Uw7eYtQsHL", // asset mgmt x-api-key
      environment.organization
    ),
    PushSupportModule.forRoot(
      "https://v63bl9b51d.execute-api.us-east-2.amazonaws.com/default",
      "https://coedyye9u0.execute-api.us-east-2.amazonaws.com/default",
      environment.organization
    ),
    LoggingModule.forRoot(
      "https://v63bl9b51d.execute-api.us-east-2.amazonaws.com/default",
      environment.organization
    ),
    AngularFireModule.initializeApp(environment.firebase),
    provideMessaging(() => getMessaging()),
  ],
  providers: [
    ReactNativeHttpService,
    ServiceBridge,
    //{provide: APP_BASE_HREF, useValue: '/e9249df9-14c4-417f-859a-ef77d97467c9'},
    {
      provide: FunctionalityService,
      useClass: EnvironmentFunctionalityService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [PwaService],
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule implements OnInit {
  user;
  userSubscription;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private viewportScroller: ViewportScroller,
    private logging: LoggingService,
    private auth: CustomAuthService,
    private serviceBridge: ServiceBridge
  ) {
    // this.userSubscription = this.auth.user$.subscribe((user) => {
    //   if (user) {
    //     this.user = user;
    //   }
    // });
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckStart) {
        this.spinner.show();
      }
      if (
        event instanceof GuardsCheckEnd ||
        event instanceof NavigationCancel
      ) {
        this.spinner.hide();
      }
      if (event instanceof NavigationEnd) {
        this.logging.logPageVisit(event.url, this.user);
      }
    });
    this.loadHomeContribution();
  }

  private currentMethods: any;
  ngOnInit() {
    window.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "getService") {
        const service = this.serviceBridge.getService(data.serviceName);
        this.currentMethods = service;
        const methods = Object.keys(service).reduce((acc, key) => {
          acc[key] = key;
          return acc;
        }, {});
        (event.source as Window).postMessage(
          JSON.stringify({ type: "serviceResult", methods }),
          event.origin
        );
      } else if (data.type === "callMethod") {
        if (this.currentMethods && this.currentMethods[data.methodName]) {
          const result = this.currentMethods[data.methodName](...data.args);
          (event.source as Window).postMessage(
            JSON.stringify({
              type: "methodResult",
              methodName: data.methodName,
              result,
            }),
            event.origin
          );
        }
      }
    });
  }

  async loadHomeContribution() {
    // importing this way to create a chunked library
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "home-contribution" */
      "home-contribution"
    ).then((hc) => {
      var routes = _.concat(
        hc.HomeContributionComponent.routes,
        this.router.config
      );
      if (hc.HomeContributionComponent.guardRoot) {
        routes = _.map(routes, (rt) => {
          console.log(rt);
          //return rt.path === `logged-out` || rt.path === ``
          // TODO make the blank route (welcome screen, need to not guard if giving a message)
          return rt.path === `logged-out` ||
            rt.path === `login` ||
            rt.path === ``
            ? rt
            : {
                path: rt.path,
                pathMatch: rt.pathMatch,
                matcher: rt.matcher,
                component: rt.component,
                redirectTo: rt.redirectTo,
                outlet: rt.outlet,
                canActivate: _.uniq(
                  _.concat(rt.canActivate ? rt.canActivate : [], [
                    AuthGuard,
                    //RoleGuard, TODO each contributed link should ask if we want to guard or not
                  ])
                ),
                canActivateChild: rt.canActivateChild,
                canDeactivate: rt.canDeactivate,
                canLoad: rt.canLoad,
                data: rt.data,
                resolve: rt.resolve,
                children: rt.children,
                loadChildren: rt.loadChildren,
                runGuardsAndResolvers: rt.runGuardsAndResolvers,
              };
        });
      }
      // TODO add else statement if no guard root or rather get rid of this option all together

      console.log("resetting");
      this.router.resetConfig(routes);

      console.log("re navigate");
      // now that we have loaded the new / contributed routes, lets navigate
      // again since we may have landed on a page not found
      this.router.navigateByUrl(this.router.url);
    });
  }
}
