import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { FunctionalityService } from "mn-common";
import { HomeLinksContributionComponent } from "home-links-contribution";
import { CustomAuthService } from "auth";
import { flatMap, mergeMap, tap } from "rxjs/operators";
import { FeatureService } from "src/app/services/features.service";
import { of } from "rxjs";
import { SideNavigatorService } from "src/app/services/side-navigator.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  showScreen = false;
  features = [];
  firstName;

  constructor(
    private functionalityService: FunctionalityService,
    public sideNavigatorService: SideNavigatorService,
    private router: Router,
    private authService: CustomAuthService,
    private featureService: FeatureService
  ) {}

  ngOnDestroy(): void {
    document.body.classList.remove("home-header-background");
  }

  ngOnInit(): void {
    document.body.classList.add("home-header-background");

    this.authService.getUserInfo().subscribe((data) => {
      console.log(data);
      this.firstName = data.firstname;
    });

    this.featureService.getFeatureDescriptions().then((data) => {
      // Flatten the array of features from each descriptor
      console.log(data);
      this.features = data;
    });

    // this.featureService.getFeatureDescriptions().subscribe((data) => {
    //   console.log(data);
    //   this.features = data;
    // });
  }

  navigateToFeature(path: string, queryParam): void {
    this.router.navigate(["/react-test", path.replace(/\//g, "")], {
      queryParams: { navigationParam: queryParam },
    });
  }

  getFeatureIconUrl(feature) {
    return `${feature.baseUrl}${feature.homeIcon}`;
  }

  ngAfterViewChecked(): void {}
}
