<div id="app-home">
  <div id="home-header">
    <div class="non-title-container">
      <button
        id="menu-button"
        (click)="sideNavigatorService.toggleSideNavigator()"
      >
        <img src="assets/redesign/home/menu-icon.svg" alt="Menu" />
      </button>
    </div>
  </div>
  <div id="home-body">
    <div id="overlay-div">
      <div id="home-greeting" class="small-title">
        Welcome <b>{{ firstName }}</b
        >,
      </div>
      <div id="home-title" class="large-title">Medic<br />Now</div>
      <div id="home-logo">
        <img
          id="home-logo-img"
          src="assets/redesign/home/default-logo.png"
          alt="Logo"
        />
      </div>
    </div>
    <div id="feature-scrollable-container">
      <div id="feature-container">
        <div
          *ngFor="let feature of features"
          class="feature-tile"
          (click)="navigateToFeature(feature.defaultPath, feature.defaultRoute)"
        >
          <div class="feature-icon-container">
            <img
              [src]="getFeatureIconUrl(feature)"
              alt="{{ feature.homeName }}"
            />
          </div>
          <div id="home-icon-label-container">
            <p class="home-icon-label">{{ feature.homeName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="spacer"></div>
  <app-footer id="app-footer"></app-footer>
</div>
